//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'GridContainer',
  props: {
    content: {
      type: Array,
      default: () => []
    },
    division: {
      type: String,
      default: 'auto'
    },
    divisionBorder: {
      type: Boolean,
      default: false
    },
    headline: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const gridStyling = computed(() => {
      // set amount of grid cols
      const gridStyle = [
        'grid-cols-1',
        props.content.length >= 2 ? 'sm:grid-cols-2' : ''
      ]
      if (props.division === 'auto') {
        gridStyle.push(props.content.length >= 3 ? 'md:grid-cols-3' : null)
        gridStyle.push(props.content.length >= 4 ? 'lg:grid-cols-4' : null)
      } else {
        gridStyle.push(props.content.length >= 2 ? 'md:grid-cols-3' : null)
      }

      return gridStyle
    })

    function columnStyling (position) {
      const colStyle = ['boder-r-0  border-petrol-200']

      if (props.divisionBorder && position !== props.content.length) {
        // border styling for grid elements:
        colStyle.push('pb-4 border-b-2 sm:pb-0 sm:border-b-0')
        if (props.content.length === 2 && position % 2 === 1) { // setting for sm screens & grid with 2 elements
          colStyle.push('sm:pr-4 sm:border-r-2')
        }
        if (props.division === 'auto') {
          if (props.content.length >= 2) {
            colStyle.push(position % 2 === 1 ? 'sm:pr-4 sm:border-r-2' : '')
          }
          if (props.content.length >= 3) { // setting for md screens & grid with 3 elements
            position % 3 === 0 ? colStyle.push('md:pr-0 md:border-r-0') : colStyle.push('md:pr-4 md:border-r-2')
          }
          if (props.content.length >= 4) { // setting for lg screens & grid with 4+ elements
            position % 4 === 0 ? colStyle.push('lg:pr-0 lg:border-r-0') : colStyle.push('lg:pr-4 lg:border-r-2')
          }
        }
      }

      // expansion of elements:
      if (props.division === 'two-one-third') {
        position % 2 ? colStyle.push('col-span-1 md:col-span-2') : colStyle.push('col-span-1')
      } else if (props.division === 'one-two-third') {
        position % 2 ? colStyle.push('col-span-1') : colStyle.push('col-span-1 md:col-span-2')
      }

      return colStyle
    }

    return {
      gridStyling,
      columnStyling
    }
  }
})
